import PropTypes from "prop-types";
import React from "react";
import advancedFormat from "dayjs/plugin/advancedFormat";
import dayjs from "dayjs";

dayjs.extend(advancedFormat);

export function Time({ children, format }) {
  if (!children) {
    return null;
  }
  const t = dayjs(children).format(format);
  return <time>{t}</time>;
}

Time.propTypes = {
  children: PropTypes.string,
  format: PropTypes.string.isRequired,
};

export function Day({ children }) {
  return <Time format="M/D/YYYY">{children}</Time>;
}
