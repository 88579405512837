import * as styles from "./blog.module.scss";

import { Link, graphql, useStaticQuery } from "gatsby";

import Banner from "../components/Banner";
import { Day } from "../components/date";
import Layout from "../components/Layout";
import React from "react";
import SeoHelmet from "../components/SeoHelmet";

export default function Blogs() {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        edges {
          node {
            frontmatter {
              title
              date
              path
              summary
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SeoHelmet title="Lithic Blog" />
      <Banner
        image="blog"
        title="The Blog"
        subtitle={
          <span>
            Once in a while,
            <br />
            we have some good ideas
          </span>
        }
      />
      <div className={styles.pageContent}>
        <p className={styles.intro}>
          Part of the joy of a consultancy is that we get to see in a couple years what
          many do not experience in a decade. Occasionally the lessons we learn make
          their way to this blog, where others can revel in the absurdity, horror, or
          &mdash; occasionally &mdash; wisdom.
        </p>
        <ol className={styles.blogs}>
          {data.allMarkdownRemark.edges.map((edge) => {
            return (
              <li key={edge.node.frontmatter.path} className={styles.blog}>
                <Link key={edge.node.frontmatter.path} to={edge.node.frontmatter.path}>
                  <h3>{edge.node.frontmatter.title}</h3>
                  <p>
                    <Day>{edge.node.frontmatter.date}</Day>
                  </p>
                  <p>{edge.node.frontmatter.summary}</p>
                </Link>
              </li>
            );
          })}
        </ol>
      </div>
    </Layout>
  );
}
